.SkillsContainer{
    display: flex;
    flex-direction: column;
    padding: 0em 2em 5em;
}

.SkillsItemsContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
    column-gap: 3em;
    row-gap: 2em;
    padding: 2em 1em;
    justify-content: center;
}

.SkillItem{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    gap: 1em;    
    align-items: center;
    border: solid 1px white;
    padding: 1em;
    border-radius: 8px;
}

.SkillItem img{
    max-width: 95px;
}

.SkillItem p{
    display: flex;
    justify-content: center;
    align-items: center;
}