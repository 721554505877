.HeaderInitial {
  display: flex;
  flex-direction: column;
  color: white;
  position: initial;
  justify-content: center;
  min-height: 80vh;
}

.HeaderTitle {
  align-self: start;
  margin: 0 5em;
  font-size: xx-large;
  visibility: visible;
  position: relative;
}

.HeaderTitle h2{
  color: #60a5fa;
}

.HeaderTitle h2, .HeaderTitle p {
    
  font-family: "JelleeBold";
  font-weight: normal;
  font-style: normal;
}

.HeaderTitle a:hover {
  color: #60a5fa;
}

@media (max-width: 768px) {

  .HeaderTitle {
    margin: 0 1em;
  }
}
