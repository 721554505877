.StackContainer{
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    padding: 0 2em;
}
.StackImgContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25px,auto));
    column-gap: 3em;
    row-gap: 2em;
    justify-content: center;
}
.StackImgContainer div{
    flex-direction: column;
    color: white;
    text-align: center;
    align-self: flex-end;
    display: flex;
    width: 2.375em;
    height: 2.375em;
}
.StackImgContainer div img{
    max-width: 30px;
    max-height: 30px;
    margin: auto;
}