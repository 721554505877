
.Work{
display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
grid-gap: 40px;
padding: 20px 5em;
justify-content: center;
margin: auto;
}

.WorkTitle{
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  padding: 4em 1em 2em 1em;
  font-size: 22px;
  color: white;
}

.WorkCard{ 
background-color: #1c1b29;
border-radius: 20px;
box-shadow:  0 0 30px rgba(0,0,0,18);
text-decoration: none;
}

.WorkImg{  
position: relative;
}

.WorkImg img{
width: 100%;
display: block;
border-radius: 20px 20px 0 0;
height: 320px;
object-fit: cover;
}


.WorkCard .WorkImg .MercadoSolidario{
  object-fit: contain;
}

.WorkDescription{  
padding: 20px 10px 0;
font-family: Arial,Helvetica,sans-serif;
}
.WorkDescription>h3{
color: white;
font-weight: 600;
font-size: 18px;
}
.WorkDescription>p{
color: #a0a0a0;
font-size: 15px;
line-height: 30px;
font-weight: 400;
}

.WorkImg::after{
content: "";
height: 100%;
width: 100%;
position: absolute;
top:0;
left:0;
border-radius: 20px 20px 0 0;
opacity: 0.6;
}

.WorkCard:nth-child(3) .WorkImg::after{
  background-image: linear-gradient(135deg, #0100ec, #fb36f4);
}

.WorkSlider:nth-child(3) .WorkImg::after{
  background-image: linear-gradient(135deg, #0100ec, #fb36f4);
}

@media (max-width: 800px){
  .Body{
    height: 100%;
  }
  .Work{
    padding: 20px;
  }
}